<template>
  <WithAppBar>
    <BreadCrumbs v-if="$store.getters.vetVuetifyViews" />
    <v-row no-gutters>
      <v-col>
        <h2 class="brand-h2">Pricing</h2>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col>
        <h5 class="brand-h5">Subscription summary</h5>
        <PriceSummaryTable
          :loading="loading"
          :customer-id="customerId"
          :breakdowns="subscriptionBreakdowns"
          :total="total"
          :totalBeforeDiscount="totalBeforeDiscount"
          :totalPricePerDay="totalPricePerDay"
          :totalDiscount="totalDiscount"
          @deletePet="deletePet"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h5 class="brand-h5">How PRO PLAN Personalised Nutrition Works</h5>
        <p>
          PRO PLAN Personalised Nutrition is different from other dog food. We make food for your
          dog that is backed by science to take care of your dog. But we also add in the convenience
          of home deliveries and easy payment
        </p>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col>
        <v-card outlined elevation="0" class="height-100">
          <v-card-title class="brand-h5">Free delivery</v-card-title>
          <v-card-text>
            <p>
              PRO PLAN Personalised Nutrition delivery is always free. We deliver to your door (or
              another convenient place) using the courier Yodel.
            </p>
            <p>
              To make sure that you never run out of food, we'll email you before we send the food
              and you'll always be in control of your exact delivery date.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="0" outlined class="height-100">
          <v-card-title class="brand-h5">
            We're here to help
          </v-card-title>
          <v-card-text>
            <p>
              Need help? Don't worry. If you have any problems with the food, just call or visit
              your vet and they'll be happy to help.
            </p>
            <p>
              If you have any questions or problems with payment or delivery or just want to check
              on an upcoming order, you can always get in touch with our Customer Care team:
            </p>
            <p>
              <strong>Email: </strong>
              <a
                href="mailto:contact@personalisedproplan.co.uk"
                class="card-link"
                data-heap="nutritional-plan-click-on-email"
                >contact@personalisedproplan.co.uk</a
              >
            </p>
            <p><strong>Phone:</strong> 0800 21 21 61</p>
            <p>
              <strong>Online: </strong>
              <a href="https://www.personalisedproplan.co.uk" class="card-link"
                >https://www.personalisedproplan.co.uk</a
              >
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col>
        <h5 class="brand-h5">Terms & conditions and Privacy policy</h5>
        By clicking "Begin checkout" you agree to our
        <a href="http://www.personalisedproplan.co.uk/terms-plan" target="_blank"
          >Terms & Conditions</a
        ><br />
        Find more detail on how we store your data in our
        <a href="http://www.personalisedproplan.co.uk/terms-plan" target="_blank">Privacy policy</a
        >.
      </v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col v-if="customer && customer.isComplete">
        <v-btn @click="activateAllPets">Activate New Pets</v-btn>
      </v-col>
      <v-col v-else>
        <v-btn
          :disabled="loading || subscriptionBreakdowns.length === 0"
          color="primary"
          @click="$router.push({ name: routeNames.CHECKOUT, params: { customer_id: customerId } })"
        >
          Continue
        </v-btn>
      </v-col>
    </v-row>
  </WithAppBar>
</template>

<script>
import WithAppBar from "@/layouts/WithAppBar.vue";
import PriceBreakdown from "@/models/price-breakdown.model";
import Vue from "vue";
import PriceSummaryTable from "@/components/SubscriptionBreakdown/PriceSummaryTable.vue";
import customerService from "@/services/customer/customer.service";
import priceBreakdownService from "@/services/priceBreakdown.service";
import { RouteNames } from "@/router/routeNames";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import petService from "@/services/pet.service";

export default Vue.extend({
  name: "PricingPage",
  components: { BreadCrumbs, PriceSummaryTable, WithAppBar },
  inject: ["Api"],
  data: () => {
    return {
      subscriptionBreakdowns: null,
      total: null,
      totalBeforeDiscount: null,
      totalPricePerDay: null,
      totalDiscount: null,
      customerId: null,
      customer: null,
      loading: true
    };
  },
  async mounted() {
    this.customerId = +this.$route.params.customer_id;
    try {
      await this.loadCustomer();
      await this.loadPriceBreakdown();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async loadCustomer() {
      this.customer = await customerService.getCustomer(this.customerId);
    },
    async loadPriceBreakdown() {
      const response = await priceBreakdownService.getTotalPriceBreakdownForCustomer(
        this.customerId
      );
      this.subscriptionBreakdowns = response.subscriptions.map(sub => new PriceBreakdown({}, sub));
      this.total = response.total;
      this.totalBeforeDiscount = response.total_before_discounts;
      this.totalPricePerDay = response.total_price_per_day;
      this.totalDiscount = response.total_discount;
    },
    async activateAllPets() {
      await customerService.activatePets(this.customerId);
      await this.$router.push({ name: RouteNames.THANK_YOU });
    },
    async deletePet(petId) {
      this.loading = true;
      const ok = await petService.deletePet(petId);
      if (ok) {
        await this.loadPriceBreakdown();
      }
      this.loading = false;
    }
  }
});
</script>

<style scoped>
.height-100 {
  height: 100%;
}
</style>
